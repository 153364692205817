<template>
  <Loader :spin="isLoading"></Loader>
  <ProgressBar :value="progressValue"></ProgressBar>
  <div class="main-body" style="min-height:28rem;">
    <div class="container">
      <div class="mb-1"></div>
      <div class="row m-5">
        <div class="col-md-6 offset-md-3">
            <div class="alert alert-success" style="text-align:center;" role="alert">
              <h4 class="alert-heading">Well done!</h4>
              <p>
                <img src="uploads/complete.png" height="50" />
                  <br>
                 {{title}}
              </p>
          </div>
        </div>
        <div class="clearfix"></div>
        <a href="/" class="btn btn-success btn-xs" style="max-width:150px; margin:0 auto;">Back to home</a>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "../progressBar/ProgressBar.vue";
import Loader from "../loader/Loader.vue";
import Mixin from "../../mixin";
export default {
  components: { ProgressBar, Loader },
  mixins: [Mixin],
  data(){
      return{
          title:null,
      }
  },
  created() {
    this.progressValue = 100;
    this.pageCmsData();
  },
  methods: {
    async pageCmsData() {
      this.isLoading = true;
      const res = await this.getPageCms();
      this.isLoading = false;
      this.title = res.data[0].success_page_msg;
    },
  },
};
</script>