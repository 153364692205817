<template>
  <Loader :spin="isLoading"></Loader>
  <div class="container" style="min-height:30rem;">
    <div class="px-4 py-5 my-1 text-center">
      <h1>
        <div class="hero-title">{{ title }}</div>
      </h1>
      <div class="col-lg-6 mx-auto">
        <p class="lead mb-1">
          {{ subTitle }}
        </p>
      </div>
    </div>

    <div class="row" style="max-width: 70%; margin: auto">
      <div class="col-sm mb-2" style="text-align: center">
        <h3>{{ featureTitle }}</h3>
      </div>
      <div class="w-100"></div>
      <div v-for="(data, index) in businessType" :key="index" class="col-lg-4 home-box">
        <label :for="index">
          <div class="card div_hover pointer" style="width: 15rem; margin: 5px">
            <img
              class="thumb-img p-2"
              :src="'uploads/businessFeaturesImg/' + data.image"
              alt="Card image cap"
            />
            <div class="card-body">
              <h5
                class="card-title"
                style="text-align: center; margin-bottom: 15px"
              >
                <strong>{{ data.title }}</strong>
              </h5>
              <div
                class="custom-control custom-radio"
                style="display: grid; place-items: center"
              >
                <input
                  type="radio"
                  :id="index"
                  class="custom-control-input"
                  :value="data.id"
                  v-on:click="navigate(data.id)"
                  :checked="checkedValue == data.id"
                />
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
  <!-- <div style="height: 25rem;">
  </div> -->
</template>

<script>
import Loader from "../components/loader/Loader.vue";
import Mixin from "../mixin";
export default {
  components: { Loader },
  mixins: [Mixin],
  props: ["pageData"],
  data() {
    return {
      title: null,
      subTitle: null,
      featureTitle: null,
      buttonText: null,
      //loader
      isLoading: false,
      //loader
      checkedValue: null,
      businessType: null,
      siteImageUrl: this.serverURL + "/uploads/businessFeaturesImg/",
    };
  },
  async created() {
    this.isLoading = false;
    if (localStorage.getItem("var1")) {
      this.checkedValue = localStorage.getItem("var1");
    }
    this.pageCmsData();
    this.getData();
  },
  methods: {
    async navigate(data) {
      this.checkedValue = data;
      localStorage.setItem("var1", data);
      this.$router.push("/business-feature/"+data);
    },
    async getData() {
      this.isLoading = true;
      const res = await this.getApi("business-type");
      this.isLoading = false;
      this.businessType = res.businessType;
    },
    async pageCmsData() {
      this.isLoading = true;
      const res = await this.getPageCms();
      if (res) {
        this.isLoading = false;
      }
      this.title = res.data[0].business_type_page_title;
      this.subTitle = res.data[0].business_type_page_sub_title;
      this.featureTitle = res.data[0].business_type_page_feature_title;
      this.buttonText = res.data[0].business_type_page_button_text;
    },
  },
  watch: {
    pageData: function () {
      this.title = this.pageData.business_type_page_title;
    },
  },
};
</script>

<style scoped>
@import "./home.css";
.pointer {
  cursor: pointer;
}
.home-box {
    position: relative;
    text-align: center;
    margin: 0 auto; 
    margin-top:10px;
  }
</style>