<template>
  <div>
    <Loader />
    <Header />
    <router-view ></router-view>
    <Footer/>
  </div>
</template>
<script>
import Home from "./components/Home.vue";
import Header from "./components/layouts/Header.vue";
import Footer from "./components/layouts/Footer.vue";
import Loader from "./components/loader/Loader.vue";
import Mixin from "./mixin";
export default {
  components: { Home, Header, Footer, Loader },
  mixins:[Mixin],
  data() {
    return {
      pageCmsData: null,
    };
  },
  async created() {
    //console.log(this.getPageCms());
    // const res = await this.getPageCms();
    // this.pageCmsData = res.data[0];
    //console.log(this.pageCmsData.data[0]);
  },
};
</script>
