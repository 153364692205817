<template>
  <Loader :spin="isLoading"></Loader>
  <ProgressBar :value="progressValue"></ProgressBar>
  <div class="main-body" style="min-height:28rem;">
    <div class="container">
      <div class="row m-5">
        <div class="col-sm mb-3" style="text-align: center">
          <h3>{{ title }}</h3>
        </div>
        <div class="w-100"></div>
        <div
          v-for="(data, index) in posQuantity"
          :key="index"
          class="row mb-3 div-sizing center div_hover"
          style="max-width: 70%; margin: auto"
        >
          <label :for="index" class="pointer">
            <div
              class="col-1 pointer"
              for="customRadio1"
              style="position: relative; top: 15px"
            >
              <input
                type="radio"
                :id="index"
                class="custom-control-input"
                :value="data.id"
                v-on:click="navigate(data.id)"
                :checked="checkedValue == data.id"
              />
            </div>
            <div
              class="col-5 pointer"
              style="position: relative; left: 50px; bottom: 10px"
            >
              <span class="fieldLabel">{{ data.title }}</span>
            </div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "../progressBar/ProgressBar.vue";
import Loader from "../loader/Loader.vue";
import Mixin from "../../mixin";
export default {
  components: { ProgressBar, Loader },
  mixins: [Mixin],
  data() {
    return {
      //loader
      isLoading: false,
      //loader
      //progress value
      progressValue: null,
      //progress value
      checkedValue: null,
      posQuantity: null,
      title: null,
      buttonText: null,
    };
  },
  created() {
    this.isLoading = false;
    this.progressValue = 58;
    if (localStorage.getItem("var4")) {
      this.checkedValue = localStorage.getItem("var4");
    }
  },
  methods: {
    navigate(data) {
      this.isLoading = true;
      this.checkedValue = data;
      localStorage.setItem("var4", data);
      this.$router.push("/business-zip-code");
    },
    async pageCmsData() {
      this.isLoading = true;
      const res = await this.getPageCms();
      this.isLoading = false;
      this.title = res.data[0].pos_terminal_quantity_page_title;
      this.buttonText = res.data[0].pos_terminal_quantity_page_button_title;
    },

    async getData() {
      this.isLoading = true;
      const res = await this.getApi("pos-quantity");
      this.isLoading = false;
      console.log(res);
      this.posQuantity = res.data;
    },
  },
  mounted() {
    this.pageCmsData();
    this.getData();
  },
};
</script>

<style scoped>
@import "./css/formThree.css";
</style>