import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);


import router from './router';
app.use(router).mount('#app')

// import axios from 'axios';


// app.use( axios);
// app.config.globalProperties.$axios = axios;

if(location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    app.config.globalProperties.$hostURL = 'http://localhost:8080/#';
    app.config.globalProperties.$serverURL = 'http://localhost:8000/';
}
else {
    app.config.globalProperties.$hostURL = '';
    app.config.globalProperties.$serverURL = '';
}


