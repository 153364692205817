<template>
  <Loader :spin="isLoading"></Loader>
  <ProgressBar :value="progressValue"></ProgressBar>
  <div class="main-body" style="min-height:28rem;">
    <div class="container">
      <div class="mb-1">
        <h3 style="position: relative; text-align:center;">
          {{ title }}
        </h3>
      </div>
      <div class="row m-5">
        <div class="w-100"></div>
        <form @submit.prevent="submitData()">
          <div class="row mb-4">
            <div class="col-2"></div>
            <div class="col">
              <input
                type="text"
                v-model="companyName"
                class="div-sizing center"
                placeholder="Company Name"
                required
              />
            </div>
            <div class="col-2"></div>
          </div>

          <div class="row mb-4">
            <div class="col-2"></div>
            <div class="col">
              <input
                type="text"
                v-model="firstName"
                class="div-sizing center"
                placeholder="first name"
                required
              />
            </div>
            <div class="col-2"></div>
          </div>

          <div class="row mb-4">
            <div class="col-2"></div>
            <div class="col">
              <input
                type="text"
                v-model="lastName"
                class="div-sizing center"
                placeholder="last name"
                required
              />
            </div>
            <div class="col-2"></div>
          </div>

          <div class="row mb-4">
            <div class="col-2"></div>
            <div class="col">
              <input
                type="phone"
                v-model="phoneNumber"
                class="div-sizing center"
                placeholder="(555) 555-2231"
                required
              />
            </div>
            <div class="col-2"></div>
          </div>

          <div class="row mb-3">
            <div class="col-2"></div>
            <div
              class="col alert alert-success"
              style="margin-left: 10px; margin-right: 10px"
              role="alert"
            >
              {{ subTitle }}
            </div>
            <div class="col-2"></div>
          </div>

          <div class="row mb-3">
            <div class="col-2"></div>
            <div class="col d-grid gap-2">
              <button
                class="btn btn-success"
                style="color: #1c749f"
                type="submit"
                :disabled="!phoneNumber"
              >
                <span style="color: white">{{ buttonText }}</span>
              </button>
            </div>
            <div class="col-2"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "../progressBar/ProgressBar.vue";
import Loader from "../loader/Loader.vue";
import Mixin from "../../mixin";
export default {
  components: { ProgressBar, Loader },
  mixins: [Mixin],
  data() {
    return {
      //loader
      isLoading: false,
      //loader
      //progress value
      progressValue: null,
      //progress value
      businessType: localStorage.getItem("var1"),
      businessFeature: localStorage.getItem("var2"),
      requiredService: localStorage.getItem("var3"),
      posTerminalQuantity: localStorage.getItem("var4"),
      zipCode: localStorage.getItem("zipCode"),
      email: localStorage.getItem("email"),
      companyName: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      title: null,
      subTitle: null,
      buttonText: null,
    };
  },
  created() {
    this.progressValue = 92;
    console.log(this.posTerminalQuantity, this.email);
    this.pageCmsData();
  },
  methods: {
    async submitData() {
      this.isLoading = true;
      let status = 0;
      try {
        const res = await this.postApi("lead-data-submit", {
          businessType: this.businessType,
          businessFeature: this.businessFeature,
          requiredService: this.requiredService,
          posTerminalQuantity: this.posTerminalQuantity,
          zipCode: this.zipCode,
          email: this.email,
          companyName: this.companyName,
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          status: status,
        });
        this.isLoading = false;
        if(res.status == 200){
          localStorage.clear();
          this.$router.push("/success");
        }else{
          this.$router.push("/warning");
        }

        console.log(res);
      } catch (error) {
        console.log(error);
      }
    },
    async pageCmsData() {
      this.isLoading = true;
      const res = await this.getPageCms();
      this.isLoading = false;
      this.title = res.data[0].contact_info_page_title;
      this.subTitle = res.data[0].contact_info_page_sub_title;
      this.buttonText = res.data[0].contact_info_button_text;
    },
  },
};
</script>

<style scoped>
@import "./css/formSix.css";
</style>