<template>
  <div class="progress-box mb-3">
    <div class="progress progress-style">
      <div
        class="progress-bar"
        style="background-color: #02B514;"
        role="progressbar"
        :style="{ width: value + '%' }"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      ><strong>{{value}}%</strong></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style scoped>
@import "./progressBar.css";
</style>