import constants from './constants';
import axios from 'axios';
export default {

    methods: {
        async getPageCms(params) {
            try {
                const res = await axios.get(constants.baseUrl + constants.api + "page-cms",
                    {
                        headers: {
                            //Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (res.status == 200) {
                    return res.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getApi(url) {
            try {
                const res = await axios.get(constants.baseUrl + constants.api + url,
                    {
                        headers: {
                            //Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (res.status == 200) {
                    return res.data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async getApiWithParam(url, id) {

            try {
                const res = await axios.get(constants.baseUrl + constants.api + url + "?business_type=" + id,
                    {
                        headers: {
                            //Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (res.status == 200) {
                    return res.data;
                }
            } catch (error) {
                console.log(error);
            }

        },
        async postApi(url, data) {
            //console.log(url, data.businessType)
            try {
                const res = await axios.post(constants.baseUrl + constants.api + url,
                    {
                        data: data
                    },
                    {
                        headers: {
                            //Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (res.status == 200) {
                    return res.data;
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
}
