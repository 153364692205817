// import { options } from "laravel-mix";
import { createRouter, createWebHashHistory  } from "vue-router";
import Home from "../components/Home.vue"
import FormOne from '../components/forms/FormOne.vue';
import FormTwo from '../components/forms/FormTwo.vue';
import FormThree from '../components/forms/FormThree.vue';
import FormFour from '../components/forms/FormFour.vue';
import FormFive from '../components/forms/FormFive.vue';
import FormSix from '../components/forms/FormSix.vue';
import ProgressBar from '../components/progressBar/ProgressBar.vue';
import SuccessPage from '../components/messages/SuccessPage.vue';
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/business-feature/:id',
        name: 'business-feature',
        component: FormOne
    },
    {
        path: '/serive-required',
        name: 'serive-required',
        component: FormTwo
    },
    {
        path: '/required-pos-terminal',
        name: 'required-pos-terminal',
        component: FormThree
    },
    {
        path: '/business-zip-code',
        name: 'business-zip-code',
        component: FormFour
    },
    {
        path: '/concern-contact-email',
        name: 'concern-contact-email',
        component: FormFive
    },
    {
        path: '/contact-form',
        name: 'contact-form',
        component: FormSix
    },
    {
        path: '/success',
        name: 'SuccessPage',
        component: SuccessPage
    },
    {
        path: '/progress',
        name: 'progress',
        component: ProgressBar
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;