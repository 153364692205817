<template>
  <Loader :spin="isLoading"></Loader>
  <ProgressBar :value="progressValue"></ProgressBar>
  <div class="main-body" style="min-height:28rem;">
    <div class="container">
      <div class="row m-5">
        <div class="col-sm mb-3" style="text-align: center">
          <h3>{{ title }}</h3>
        </div>
        <div class="w-100"></div>
        <div class="row mb-3">
          <div class="col-2"></div>
          <div
            class="col alert alert-success"
            style="margin-left: 15px"
            role="alert"
          >
            {{ subTitle }}
          </div>
          <div class="col-2"></div>
        </div>
        <form @submit.prevent="submitData()">
          <div class="row mb-3">
            <div class="col-2"></div>
            <div class="col">
              <input
                type="text"
                v-model="zipCode"
                class="div-sizing center"
                placeholder="10213"
              />
            </div>
            <div class="col-2"></div>
          </div>
          <div class="row mb-3">
            <div class="col-2"></div>
            <div class="col d-grid gap-2">
              <button
                class="btn btn-success"
                style="color: #1c749f"
                type="submit"
                :disabled="!zipCode"
              >
                <span style="color: white">{{ buttonText }}</span>
              </button>
            </div>
            <div class="col-2"></div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressBar from "../progressBar/ProgressBar.vue";
import Loader from "../loader/Loader.vue";
import Mixin from "../../mixin";
export default {
  components: { ProgressBar, Loader },
  mixins: [Mixin],
  data() {
    return {
      //loader
      isLoading: false,
      //loader
      //progress value
      progressValue: null,
      //progress value
      zipCode: null,
      title: null,
      subTitle: null,
      buttonText: null,
    };
  },
  created() {
    this.isLoading = false;
    this.progressValue = 67;
    if (localStorage.getItem("zipCode")) {
      this.zipCode = localStorage.getItem("zipCode");
    }
    this.pageCmsData();
  },
  methods: {
    submitData() {
      this.isLoading = true;
      if (this.zipCode != null) {
        localStorage.setItem("zipCode", this.zipCode);
        this.$router.push("/concern-contact-email");
      }
    },
    async pageCmsData() {
      this.isLoading = true;
      const res = await this.getPageCms();
      this.isLoading = false;
      this.title = res.data[0].zip_code_page_title;
      this.subTitle = res.data[0].zip_code_page_sub_title;
      this.buttonText = res.data[0].zip_code_page_button_text;
    },
  },
};
</script>

<style scoped>
@import "./css/formFour.css";
</style>