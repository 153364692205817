<template>
  <header class="p-3 text-white" :style="{ background: activeColor }">
    <nav class="nav nav-pills nav-justified">
      <a v-if="homeSuccesPage" class="nav-item nav-link" href="javascript:void(0)"
        ><strong
          ><a href="#" class="font-decoration"> {{ title }}</a></strong
        ></a
      >
      <a v-if="!homeSuccesPage"
        class="nav-item nav-link text-white"
        href="javascript:void(0)"
        style="text-decoratio: none; cursor: pointer; font-size: 20px"
        ><span onclick="history.back()"
          ><i class="fa-solid fa-arrow-left fa-lg arrow"></i>&ensp;<span
            class="back-pos"
            >back</span
          ></span
        ></a
      >
      <a v-if="!homeSuccesPage"
        class="nav-item nav-link text-white"
        href="javascript:void(0)"
        style="text-decoratio: none; cursor: pointer; font-size: 20px"
      >
        <span v-on:click="close"><i class="fa-solid fa-xmark fa-lg"></i></span
      ></a>
      <a v-if="homeSuccesPage" class="nav-item nav-link" href="javascript:void(0)"
        ><button type="button" class="btn btn-style">
          <strong>{{ phoneNo }}</strong>
        </button></a
      >
    </nav>
    <!-- <div class="container">
      <div
        class="
          d-flex
          flex-wrap
          align-items-center
          justify-content-center justify-content-lg-start
        "
      >
        <ul
          class="
            nav
            col-12 col-lg-auto
            me-lg-auto
            mb-2
            justify-content-center
            mb-md-0
          "
        >
          <li v-if="homeSuccesPage">
            <strong
              ><a href="#" class="font-decoration"> {{ title }}</a></strong
            >
          </li>
          <li v-if="!homeSuccesPage">
            <div class="text-end backbtn">
              <span onclick="history.back()"
                ><i class="fa-solid fa-arrow-left fa-lg arrow"></i>&ensp;<span
                  class="back-pos"
                  >back</span
                ></span
              >
            </div>
          </li>
        </ul>
        <div v-if="homeSuccesPage" class="text-end">
          <button type="button" class="btn btn-style">
            <strong>{{ phoneNo }}</strong>
          </button>
        </div>
        <div v-if="!homeSuccesPage" class="text-end closebtn">
          <span v-on:click="close"
            ><i class="fa-solid fa-xmark fa-lg"></i
          ></span>
        </div>
      </div>
    </div> -->
  </header>
</template>

<script>
import Mixin from "../../mixin";
import constants from "../../constants";
export default {
  mixins: [Mixin],
  data() {
    return {
      title: null,
      logo: null,
      baseUrl: constants.baseUrl,
      id: localStorage.getItem("var1"),
      activeColor: "#02b519",
      homeSuccesPage: true,
    };
  },
  async created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await this.getApi("settings");
      console.log(res.data);
      this.title = res.settings[0].title;
      this.logo = res.settings[0].logo;
      this.phoneNo = res.settings[0].contact_number;
      console.log(this.settingsData);
    },
    close() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
  watch: {
    $route: function (newPath, oldPath) {
      if (this.$route.path == "/" || this.$route.path == "/success") {
        this.activeColor = "#02b519";
        this.homeSuccesPage = true;
      } else {
        this.activeColor = "#02b519";
        this.homeSuccesPage = false;
      }
    },
  },
};
</script>

<style scoped>
@import "./css/header.css";
</style>