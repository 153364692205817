<template>
  <footer class="bg-dark text-center text-white footer_pos mt-5">
    <!-- Grid container -->
    <div class="container p-4 pb-0">
      <!-- Section: Form -->
      <section class="">
        <!--Grid row-->
        <div class="row d-flex justify-content-center">
          <!--Grid column-->
          <div class="col-auto"></div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-md-5 col-12">
            <!-- Email input -->
            <div class="form-outline form-white mb-5">
              <h3 class="mb-5">
                {{ content }}
              </h3>
              <div class="d-grid gap-2 col-6 mx-auto">
                <button
                  class="btn text-white"
                  style="background: #02b519"
                  v-on:click="topFunction"
                >
                  {{ footerButtonText }}
                </button>
              </div>
            </div>
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-auto"></div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </section>
      <!-- Section: Form -->
    </div>
    <!-- Grid container -->

    <!-- Copyright -->
    <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
      Copyright © 2022
      <a class="text-white" href="https://mdbootstrap.com/">{{ copyRight }}</a>
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
import Mixin from "../../mixin";
export default {
  name: "Footer",
  mixins: [Mixin],
  data() {
    return {
      content: null,
      footerButtonText: null,
      copyRight: null,
    };
  },
  created() {
    this.pageCmsData();
  },
  methods: {
    async pageCmsData() {
      const res = await this.getPageCms();
      this.isLoading = false;
      this.content = res.data[0].footer_content;
      this.footerButtonText = res.data[0].footer_button_text;
      this.copyRight = res.data[0].copy_right;
    },
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style scoped>
@import "./css/footer";
</style>